<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card
            title="Situação vivenciada pela localidade devido à passagem da lama"
            titleColor="#000"
            borderColor="#e06919"
            class="mb-5"
          >
            <template v-slot:conteudo>
              <p>
                Os moradores de terrenos da localidade de Braúnas ficaram
                prejudicados com a passagem do rejeito proveniente do rompimento
                da barragem de Fundão. Anteriormente, mantinham relações
                comerciais e de trocas com os moradores das comunidades vizinhas
                destruídas pela lama, principalmente com Paracatu de Baixo.
                Soma-se a isso, a dificuldade de se encontrar mão de obra para
                realizar os trabalhos na atividade de plantio de eucalipto e da
                carvoaria, bem como nos roçados e criação de animais. Os
                moradores das comunidades vizinhas foram obrigados a se mudar
                para outros locais, causando o esvaziamento e a evasão
                populacional da região.
              </p>
              <p>
                A dificuldade no manejo dos cultivos dos quintais também afetou
                o cotidiano das famílias, que não têm mais
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >soberania alimentar</router-link
                >
                diante dos obstáculos para produzir os próprios alimentos,
                livres de agrotóxicos e fertilizantes químicos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_braunas_09.png"
          />
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_braunas_10.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Poço utilizado para a criação de peixes, cuja pesca foi prejudicada
            com o rompimento da barragem. Foto: Acervo de vistoria técnica
            realizada pela Cáritas, 2020.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-center"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Diante da impossibilidade de serem retomadas as atividades
                produtivas, os moradores da região enfrentam a decadência
                econômica e a desvalorização imobiliária das terras, além de
                conviverem com transtornos ocasionados pelas obras de construção
                do reassentamento da comunidade de Paracatu de Baixo, por onde
                trafegam veículos pesados, além da poeira que disseminam pelo
                ar. Em época de chuvas, parte da terra do reassentamento desce
                para os terrenos de Braúnas, assoreando nascentes, prejudicando
                o trânsito nas estradas, que ficam cobertas por lama.
              </p>
              <p>
                Na paisagem da região são observadas diversas espécies arbóreas
                secando e, de acordo com os relatos dos moradores, espécies de
                pássaros já não são mais vistos, além dos peixes que
                desapareceram. O isolamento temporário ocorrido logo após o
                desastre também causou transtornos para a população local, que
                ficou sem acesso até que as pontes e estradas fossem
                reconstruídas. Nesse período, os moradores recorreram a caminhos
                alternativos, com trajetos mais longos e em estado ruim de
                conservação. Com a perda de acesso mesmo que temporariamente, o
                escoamento da produção ficou prejudicada, assim como a chegada
                de insumos. Durante esse período, a falta de acesso acarretou a
                perda de produção pela falta de manejo das áreas isoladas.
              </p>
              <p class="text-center">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  margin-top: 2em;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
